'use client';
import styled from 'styled-components';
import { colors, breakPoints } from '@/styles';

export const Card = styled.div<{
  $compressed?: boolean;
  $snug?: boolean;
  $error?: boolean;
  $gap?: number;
  $stretch: boolean;
  $shadow?: boolean;
}>`
  display: flex;
  flex-direction: column;
  gap: ${(props) => `${props.$gap}px`};
  padding: ${(props) => (props.$compressed ? '10px 20px' : props.$snug ? '10px' : '25px')};
  border: 1px solid ${(props) => (props.$error ? colors.semantic.negative.primary : colors.secondary.lightGray2)};
  border-radius: 5px;
  background-color: ${(props) => (props.$error ? `${colors.semantic.negative.light} !important` : 'transparent')};
  color: ${(props) => (props.$error ? colors.semantic.negative.primary : 'inherit')};
  width: ${(props) => (props.$stretch ? '100%' : 'auto !important')};

  box-shadow: ${({ $shadow }) => ($shadow ? '2px 2px 5px 0px rgba(0, 0, 0, 0.05)' : 'none')};
  line-height: 1.5;

  @media (max-width: 480px) {
    padding: 15px;
    gap: 15px;
  }
`;

export const InfoCard = styled(Card)`
  background-color: ${colors.semantic.information.light};
  border-color: ${colors.semantic.information.border};
  color: ${colors.semantic.information.primary};
`;

export const AlternateCard = styled(Card)`
  background-color: ${colors.secondary.lightGray1};
`;

export const WarningCard = styled(Card)`
  background-color: ${colors.semantic.notice.light};
  border-color: ${colors.semantic.notice.border};
  color: ${colors.semantic.notice.dark};
`;

export const ErrorCard = styled(Card)`
  background-color: ${colors.semantic.negative.light};
  border-color: ${colors.semantic.negative.primary};
  color: ${colors.semantic.negative.dark};
`;

export const BorderFadeCard = styled.div<{ $borderWidth?: number; $shadow?: boolean; $background?: string }>`
  display: flex;
  flex-direction: column;
  height: 100%;
  border-radius: 8px;
  background-image: linear-gradient(#f87a7a, #f9947d, #f57ab1, #e07de8, #dbb2ed, #e8cef8);
  padding: ${(props) => `${props.$borderWidth || 1}px`};
  box-shadow: ${({ $shadow }) => ($shadow ? '2px 2px 5px 0px rgba(0, 0, 0, 0.1)' : 'none')};

  & > * {
    border-radius: 7px;
    flex: 1;
    border: none;
    background: ${({ $background }) => $background || colors.primary.white};
    /* padding: 20px; */
  }
`;
