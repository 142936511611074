'use client';
import styled from 'styled-components';
import { colors, breakPoints } from '@/styles';

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex: 0 1 auto;
  /* width: 100%; */
  /* margin-left: auto; */
  /* margin-right: auto; */

  & > * {
    align-items: flex-start;
  }

  @media (max-width: ${breakPoints.mobile}) {
    &.mobile-wrap {
      flex-direction: column;
      align-items: flex-start !important;
      /* border: 1px; */
      & > * {
        align-items: flex-start !important;
        justify-content: flex-start !important;
      }
    }
  }
`;
