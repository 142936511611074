'use client';
import styled from 'styled-components';
import { colors, breakPoints } from '@/styles';

export const Box = styled.div<{ $padding?: number; $debug?: boolean }>`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: ${(props) => (props.$padding ? `${props.$padding}px` : '10px')};
  border: ${(props) => (props.$debug ? `1px dashed ${colors.semantic.positive.dark}` : 'none')};
`;
